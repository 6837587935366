import React, { useState, useCallback, Dispatch, SetStateAction } from 'react';
import { FiUploadCloud } from 'react-icons/fi';
import { UploadedPhotoFileData, Loader, PrimaryButton } from 'shared';
import DropZone from 'shared/ui/DropZone';

type Step1Props = {
  onSetStep: Dispatch<SetStateAction<number>>;
  uploadProgress: number;
  uploadedPhoto: string;
  hasUploadStarted: boolean;
  onDeleteUploaded: () => void;
  onFileUpload: (file: File) => void;
  fileData: UploadedPhotoFileData | null;
  isFaceChecking: boolean;
};

const Step1: React.FC<Step1Props> = ({
  onSetStep,
  uploadProgress,
  uploadedPhoto,
  hasUploadStarted,
  onDeleteUploaded,
  onFileUpload,
  fileData,
  isFaceChecking
}) => {
  // TODO: Add isDragActive zone change UI
  const [isDragActive, setIsDragActive] = useState(false);

  const handleDrag = useCallback(
    (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === 'dragenter' || e.type === 'dragover') {
        setIsDragActive(true);
      } else if (e.type === 'dragleave') {
        setIsDragActive(false);
      }
    },
    [setIsDragActive]
  );

  const handleUpload = useCallback(
    (files: File[]) => {
      if (files && files[0]) {
        onFileUpload(files[0]);
      }
    },
    [onFileUpload]
  );

  const handleChange = (e: any) => {
    e.preventDefault();
    handleUpload(e.currentTarget.files);
    e.target.value = '';
  };

  const handleDrop = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();

      handleUpload(e.dataTransfer.files);

      // setIsDragActive(false);
    },
    [handleUpload]
  );

  return (
    <>
      <h1 className="yep_ex-text-lg yep_ex-font-semibold sm:yep_ex-mb-2">
        Upload a front face photo
      </h1>
      <p className="yep_ex-text-neutral-600 yep_ex-text-sm yep_ex-mb-2 sm:yep_ex-mb-8">
        Please ensure that you upload a front-facing photo for optimal results.
      </p>

      {hasUploadStarted ? (
        <div
          className={`yep_ex-text-center yep_ex-rounded-xl yep_ex-p-4 yep_ex-border yep_ex-min-h-[300px] yep_ex-flex yep_ex-flex-col yep_ex-justify-center yep_ex-text-neutral-400 ${
            uploadedPhoto
              ? 'yep_ex-border-primary-400'
              : 'yep_ex-border-gray-200'
          }`}
        >
          <div className="yep_ex-flex yep_ex-items-center yep_ex-relative yep_ex-h-full">
            {uploadedPhoto && (
              <div
                className="yep_ex-absolute yep_ex-top-0 yep_ex-right-1 yep_ex-cursor-pointer"
                onClick={onDeleteUploaded}
              >
                <svg
                  width="18"
                  height="20"
                  viewBox="0 0 18 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 5L15.1327 17.1425C15.0579 18.1891 14.187 19 13.1378 19H4.86224C3.81296 19 2.94208 18.1891 2.86732 17.1425L2 5M7 9V15M11 9V15M12 5V2C12 1.44772 11.5523 1 11 1H7C6.44772 1 6 1.44772 6 2V5M1 5H17"
                    stroke="#111827"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            )}
            <div
              className="yep_ex-border yep_ex-border-gray-100 yep_ex-w-24 yep_ex-h-24 yep_ex-rounded yep_ex-relative yep_ex-shrink-0 yep_ex-mr-4 yep_ex-flex 
            yep_ex-items-center yep_ex-justify-center yep_ex-overflow-hidden"
            >
              {uploadedPhoto ? (
                <img
                  src={uploadedPhoto}
                  alt="Uploaded photo"
                  className="yep_ex-w-full yep_ex-h-full yep_ex-object-contain yep_ex-z-10"
                />
              ) : (
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="yep_ex-w-1/3 yep_ex-h-1/3 yep_ex-z-10"
                >
                  <path
                    d="M9.8 13H3.62091C3.21704 13 3.0151 13 2.92159 12.9201C2.84045 12.8508 2.79739 12.7469 2.80577 12.6405C2.81541 12.5179 2.95821 12.3751 3.24379 12.0895L8.91242 6.42091C9.17643 6.15691 9.30844 6.0249 9.46066 5.97544C9.59455 5.93193 9.73878 5.93193 9.87268 5.97544C10.0249 6.0249 10.1569 6.1569 10.4209 6.42091L13 9V9.8M9.8 13C10.9201 13 11.4802 13 11.908 12.782C12.2843 12.5903 12.5903 12.2843 12.782 11.908C13 11.4802 13 10.9201 13 9.8M9.8 13H4.2C3.0799 13 2.51984 13 2.09202 12.782C1.71569 12.5903 1.40973 12.2843 1.21799 11.908C1 11.4802 1 10.9201 1 9.8V4.2C1 3.0799 1 2.51984 1.21799 2.09202C1.40973 1.71569 1.71569 1.40973 2.09202 1.21799C2.51984 1 3.0799 1 4.2 1H9.8C10.9201 1 11.4802 1 11.908 1.21799C12.2843 1.40973 12.5903 1.71569 12.782 2.09202C13 2.51984 13 3.0799 13 4.2V9.8M6 4.66667C6 5.40305 5.40305 6 4.66667 6C3.93029 6 3.33333 5.40305 3.33333 4.66667C3.33333 3.93029 3.93029 3.33333 4.66667 3.33333C5.40305 3.33333 6 3.93029 6 4.66667Z"
                    stroke="#7F56D9"
                    strokeWidth="1.33333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </div>
            <div className="yep_ex-text-sm yep_ex-w-full yep_ex-text-left">
              <div className="yep_ex-text-black yep_ex-font-medium">
                {fileData?.name}
              </div>
              <div className="yep_ex-mb-2">{fileData?.size} Kb</div>
              <div className="yep_ex-flex yep_ex-items-center yep_ex-w-full">
                <div className="yep_ex-w-11/12 yep_ex-border yep_ex-border-gray-200 yep_ex-rounded-full yep_ex-h-3 yep_ex-mr-2 yep_ex-relative">
                  <div
                    style={{ width: `${uploadProgress}%` }}
                    className={`yep_ex-absolute yep_ex-top-0 yep_ex-left-0 yep_ex-h-full yep_ex-bg-primary-700 yep_ex-border yep_ex-border-primary-700 yep_ex-rounded-l-full ${
                      uploadProgress === 100 && 'yep_ex-rounded-r-full'
                    }`}
                  ></div>
                </div>
                <div className="yep_ex-text-black yep_ex-font-medium">
                  {uploadProgress}%
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <DropZone
          onDrag={handleDrag}
          onDrop={handleDrop}
          as="label"
          props={{
            htmlFor: 'input-file-upload',
            className: 'yep_ex-cursor-pointer yep_ex-min-w-full yep_ex-h-full'
          }}
        >
          <div
            className={`yep_ex-text-center yep_ex-rounded-xl yep_ex-p-4 yep_ex-border yep_ex-min-h-[300px] yep_ex-flex yep_ex-flex-col yep_ex-justify-center yep_ex-text-neutral-400 ${
              uploadedPhoto
                ? 'yep_ex-border-primary-400'
                : 'yep_ex-border-gray-200'
            }`}
          >
            <input
              type="file"
              id="input-file-upload"
              name="input-file-upload"
              multiple={false}
              accept="image/png, image/jpeg, image/jpg"
              className="yep_ex-hidden"
              onChange={handleChange}
            />
            <div className="yep_ex-w-11 yep_ex-h-11 yep_ex-rounded-full yep_ex-bg-gray-50 yep_ex-mx-auto yep_ex-flex yep_ex-justify-center yep_ex-items-center yep_ex-mb-3 yep_ex-cursor-pointer">
              <div className="yep_ex-w-8 yep_ex-h-8 yep_ex-rounded-full yep_ex-bg-gray-100 yep_ex-flex yep_ex-items-center yep_ex-justify-center yep_ex-mx-auto">
                {isFaceChecking ? (
                  <Loader />
                ) : (
                  <FiUploadCloud className="mx-auto text-black text-lg" />
                )}
              </div>
            </div>
            <div className="yep_ex-text-sm yep_ex-mb-1">
              <span className="yep_ex-text-primary-700 yep_ex-font-semibold yep_ex-mr-1 yep_ex-cursor-pointer hover:yep_ex-underline">
                Click to upload
              </span>
              or drag and drop
            </div>
            <div className="yep_ex-text-xs">
              PNG or JPG formats, HD (1920 high x 1080 wide), larger is better
            </div>
          </div>
        </DropZone>
      )}

      <div
        className="yep_ex-absolute yep_ex-flex yep_ex-flex-col-reverse sm:yep_ex-flex-row yep_ex-gap-2 yep_ex-mt-4 yep_ex-justify-between yep_ex-items-center 
        yep_ex-bottom-0 yep_ex-left-0 sm:yep_ex-left-6 sm:yep_ex-right-4 sm:yep_ex-bottom-4 yep_ex-bg-white yep_ex-z-20 sm:yep_ex-z-auto yep_ex-w-full sm:yep_ex-w-auto yep_ex-py-4 sm:yep_ex-py-0"
      >
        <button
          onClick={() => onSetStep((prevState) => prevState - 1)}
          className="yep_ex-bg-white yep_ex-border yep_ex-border-black yep_ex-text-sm yep_ex-font-semibold yep_ex-rounded yep_ex-py-2.5 yep_ex-px-16 yep_ex-min-w-[180px]"
        >
          Back
        </button>
        <PrimaryButton
          onClick={() => onSetStep((prevState) => prevState + 1)}
          className={`yep_ex-rounded yep_ex-py-2.5 yep_ex-px-16 yep_ex-min-w-[180px] }`}
          disabled={!uploadedPhoto}
        >
          Next
        </PrimaryButton>
      </div>
    </>
  );
};

export default Step1;

import React, { Dispatch, SetStateAction } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { UploadedPhotoDetails, GenderOptions, PrimaryButton } from 'shared';
import SelectInput from 'shared/ui/SelectInput';

// import SelectInput from '../../../common/SelectInput';

type Step2Props = {
  onSetStep: Dispatch<SetStateAction<number>>;
  onUpdateDetails: (details: UploadedPhotoDetails) => void;
  uploadedPhotoDetails: UploadedPhotoDetails;
  hideBackButton?: boolean;
};

const Step2: React.FC<Step2Props> = ({
  onSetStep,
  onUpdateDetails,
  uploadedPhotoDetails,
  hideBackButton = 'false'
}) => {
  return (
    <div className="yep_ex-text-left">
      <h1 className="yep_ex-text-lg yep_ex-font-semibold yep_ex-mb-2">
        Talking Picture details
      </h1>
      <p className="yep_ex-text-neutral-400 yep_ex-mb-4 sm:yep_ex-mb-8">
        Please enter your Talking Picture details
      </p>
      <Formik
        initialValues={uploadedPhotoDetails}
        validationSchema={Yup.object({
          name: Yup.string().required('Please enter name'),
          age: Yup.number()
            .positive()
            .integer()
            .required('Please enter age')
            .nullable()
            .typeError('Age must be number'),
          gender: Yup.string()
            .required('Please select gender')
            .oneOf(['Male', 'Female', 'Other'])
            .typeError('Please choose gender')
        })}
        onSubmit={(values) => {
          onSetStep((prevState) => prevState + 1);
          onUpdateDetails(values);
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <div className="yep_ex-flex yep_ex-flex-col sm:yep_ex-flex-row">
              <div className="sm:yep_ex-w-7/12">
                <div className="yep_ex-mb-2 yep_ex-text-sm yep_ex-relative">
                  <label htmlFor="name" className="yep_ex-mb-1.5 yep_ex-block">
                    Name
                  </label>
                  <Field
                    id="name"
                    name="name"
                    type="text"
                    className={`yep_ex-block yep_ex-rounded-lg yep_ex-w-full ${
                      formik.touched.name && formik.errors.name
                        ? 'yep_ex-border-red-500'
                        : 'yep_ex-border-gray-300'
                    }`}
                  />
                  <ErrorMessage
                    name="name"
                    render={(msg: string) => (
                      <div className="yep_ex-absolute yep_ex-text-red-500 yep_ex-text-xs sm:yep_ex-mt-1 yep_ex-text-right yep_ex-right-0  yep_ex-w-full">
                        {msg}
                      </div>
                    )}
                  />
                </div>

                <div className="yep_ex-mb-2 yep_ex-text-sm yep_ex-relative">
                  <label htmlFor="age" className="yep_ex-mb-1.5 yep_ex-block">
                    Age
                  </label>
                  <Field
                    id="age"
                    name="age"
                    type="text"
                    className={`yep_ex-block yep_ex-rounded-lg yep_ex-w-full ${
                      formik.touched.age && formik.errors.age
                        ? 'yep_ex-border-red-500'
                        : 'yep_ex-border-gray-300'
                    }`}
                  />
                  <ErrorMessage
                    name="age"
                    render={(msg: string) => (
                      <div className="yep_ex-absolute yep_ex-text-red-500 yep_ex-text-xs sm:yep_ex-mt-1 yep_ex-text-right yep_ex-right-0 yep_ex-w-full">
                        {msg}
                      </div>
                    )}
                  />
                </div>

                <div className="yep_ex-mb-2 yep_ex-text-sm yep_ex-relative">
                  <label
                    htmlFor="gender"
                    className="yep_ex-mb-1.5 yep_ex-block"
                  >
                    Gender
                  </label>
                  <SelectInput
                    name="gender"
                    options={['Male', 'Female', 'Other']}
                    value={formik.values.gender}
                    onChange={(option: GenderOptions) =>
                      formik.setFieldValue('gender', option)
                    }
                    placeholder="Select gender..."
                    error={formik.touched.gender && formik.errors.gender}
                    borderColorClass="yep_ex-border-gray-300"
                  />
                  <ErrorMessage
                    name="gender"
                    render={(msg: string) => (
                      <div className="yep_ex-absolute yep_ex-text-red-500 yep_ex-text-xs yep_ex-mt-1 yep_ex-text-right yep_ex-right-0">
                        {msg}
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
            <div
              className="yep_ex-absolute yep_ex-flex yep_ex-flex-col-reverse sm:yep_ex-flex-row yep_ex-gap-2 yep_ex-mt-4 yep_ex-justify-between yep_ex-items-center 
              yep_ex-bottom-0 yep_ex-left-0 sm:yep_ex-left-6 sm:yep_ex-right-4 sm:yep_ex-bottom-4 yep_ex-bg-white yep_ex-z-20 sm:yep_ex-z-auto yep_ex-w-full sm:yep_ex-w-auto yep_ex-py-4 sm:yep_ex-py-0"
            >
              <button
                onClick={() => onSetStep((prevState) => prevState - 1)}
                className={`yep_ex-bg-white yep_ex-border yep_ex-border-black yep_ex-text-sm yep_ex-font-semibold yep_ex-rounded yep_ex-py-2.5 yep_ex-px-8 sm:yep_ex-px-16 yep_ex-min-w-[180px] ${
                  hideBackButton ? 'yep_ex-invisible' : ''
                }`}
              >
                Back
              </button>
              <PrimaryButton
                type="submit"
                className="yep_ex-py-2.5 yep_ex-px-8 sm:yep_ex-px-16 yep_ex-min-w-[180px]"
              >
                Next
              </PrimaryButton>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Step2;

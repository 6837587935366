import { ThreeCircles } from 'react-loader-spinner';

type LoaderProps = {
  size?: number;
  color?: string;
  wrapperClass?: string;
};

export const Loader = ({ size, color, wrapperClass }: LoaderProps) => {
  return (
    <ThreeCircles
      color={color || '#112943'}
      height={size || 25}
      width={size || 25}
      ariaLabel="three-circles-rotating"
      wrapperClass={wrapperClass}
      // TODO: Add circle colors
      // outerCircleColor="#112999"
      // innerCircleColor="#992943"
      // middleCircleColor="#110043"
    />
  );
};

import React, { ComponentType } from 'react';
import { Loader } from './Loader';

export function withLoader<T extends object>(
  Component: ComponentType<T>,
  isLoading: boolean
) {
  function HOC({
    loaderClassName = '',
    loaderSize = 50,
    ...props
  }: T & { loaderClassName?: string; loaderSize?: number }) {
    return (
      <>
        {isLoading ? (
          <Loader
            size={loaderSize}
            wrapperClass={`yep_ex-flex yep_ex-justify-center yep_ex-items-center ${loaderClassName}`}
          />
        ) : (
          <Component {...(props as T)} />
        )}
      </>
    );
  }

  return HOC;
}

import { AvatarItem, avatarStore } from 'entities/avatar';
import { IAvatar } from 'shared';

type AvatarListProps = {
  avatars: IAvatar[];
  title: string;
  isCustomTalkingPhoto?: boolean;
};

export const AvatarsList = ({ avatars, title }: AvatarListProps) => {
  const [currentAvatar, setCurrentAvatar] = avatarStore((state) => [
    state.currentAvatar,
    state.setCurrentAvatar
  ]);

  return (
    <>
      {avatars && avatars.length > 0 && (
        <div className="yep_ex-mt-2 yep_ex-mb-6">
          <h3 className="yep_ex-text-sm yep_ex-text-black yep_ex-font-semibold yep_ex-mb-2">
            {title}
          </h3>
          <div className="yep_ex-grid yep_ex-grid-cols-3 md:yep_ex-grid-cols-2 yep_ex-gap-2 yep_ex-font-inter">
            {avatars?.map((avatar, inx) => (
              <AvatarItem
                key={inx}
                avatar={avatar}
                onChooseAvatar={(avatar) => setCurrentAvatar(avatar)}
                isActive={currentAvatar?.id === avatar.id}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

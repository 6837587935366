import { httpService } from 'shared';

export const uploadPhotoApi = {
  detectFace: async (file: FormData) => {
    const { data } = await httpService.post('/facedetection', file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return data;
  }
};

import React, { useState } from 'react';
import clsx from 'clsx';

import { FaInfoCircle } from 'react-icons/fa';
import { IAvatar, Modal } from 'shared';
import CustomTalkingPhotoInfo from './CustomTalkingPhotoInfo';

type AvatarItemProps = {
  avatar: IAvatar;
  onChooseAvatar: (avatar: IAvatar) => void;
  isActive?: boolean;
  openModal?: (avatar: IAvatar) => void;
};

export const AvatarItem: React.FC<AvatarItemProps> = ({
  avatar,
  onChooseAvatar,
  isActive
}) => {
  const imgUrl =
    avatar.inputImageUrl ?? avatar.menuImageUrl ?? avatar.rawImageUrl;

  const [isInfoOpened, setIsInfoOpened] = useState(false);

  return (
    <>
      <div
        onClick={() => onChooseAvatar(avatar)}
        className={clsx(
          `yep_ex-relative yep_ex-group`,
          isActive ? 'yep_ex-cursor-default' : 'yep_ex-cursor-pointer'
        )}
      >
        {imgUrl && (
          <img
            src={imgUrl}
            alt={avatar.name}
            className={clsx(
              `yep_ex-w-full yep_ex-aspect-square yep_ex-border yep_ex-rounded yep_ex-object-cover`,
              isActive
                ? 'yep_ex-border-neutral-400'
                : 'yep_ex-border-neutral-100'
            )}
          />
        )}
        <div
          className={clsx(
            'group-hover:yep_ex-opacity-100 yep_ex-block yep_ex-absolute yep_ex-bottom-0 yep_ex-top-2/3 yep_ex-left-0 yep_ex-right-0 yep_ex-rounded yep_ex-bg-gradient-to-t yep_ex-from-black yep_ex-opacity-0 yep_ex-transition-opacity yep_ex-overflow-hidden',
            isActive ? 'yep_ex-opacity-100' : 'yep_ex-opacity-50'
          )}
        />
        {avatar.talkingPhoto && avatar.customAvatar && (
          <button
            onClick={() => {
              setIsInfoOpened(true);
            }}
            className={clsx(
              'yep_ex-absolute yep_ex-w-5 yep_ex-h-5 !yep_ex-rounded-full yep_ex-top-1 yep_ex-right-0.5 yep_ex-justify-center yep_ex-items-center yep_ex-opacity-75 hover:yep_ex-opacity-100 yep_ex-transition-opacity yep_ex-hidden group-hover:yep_ex-block'
            )}
          >
            <FaInfoCircle className="yep_ex-text-sm yep_ex-bg-white yep_ex-rounded-full" />
          </button>
        )}
        <span
          className={clsx(
            'yep_ex-text-white yep_ex-text-sm yep_ex-inline group-hover:yep_ex-opacity-100 yep_ex-opacity-0 yep_ex-absolute yep_ex-bottom-2 yep_ex-left-2 yep_ex-transition-opacity',
            isActive ? 'yep_ex-opacity-100' : 'yep_ex-opacity-80'
          )}
        >
          {avatar.name}
        </span>
      </div>
      {isInfoOpened && (
        <Modal
          onClose={() => setIsInfoOpened(false)}
          open={isInfoOpened}
          noPadding
          withCloseButton
          className="md:yep_ex-max-w-[480px]"
        >
          <CustomTalkingPhotoInfo
            avatar={avatar}
            onClose={() => setIsInfoOpened(false)}
          />
        </Modal>
      )}
    </>
  );
};

import { JoinWaitlistRequest } from 'shared';
import { waitlistApi } from '../api';
import { AxiosError } from 'axios';
import { appStore } from 'App/store';

export const joinWaitlist = async (
  data: Omit<JoinWaitlistRequest, 'email'>,
  onError: (message: string) => void
) => {
  try {
    const email = appStore.getState().user?.email || '';
    return await waitlistApi.joinWaitlist({ ...data, email });
  } catch (error) {
    if (error instanceof Error) {
      if (error instanceof AxiosError && error.response) {
        if (error.response.status === 400) {
          onError(error.response?.data.detail);
        } else {
          onError(
            `There was an error when we tried to sent your request. Error message: ${error.message}`
          );
        }
      } else {
        onError(
          `There was an error when we tried to sent your request. Error message: ${error.message}`
        );
      }
    }
  }
};

import React, { useState, useCallback, useEffect } from 'react';
import Step0 from './steps/Step0';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import {
  UploadedPhotoDetails,
  UploadedPhotoFileData,
  getErrorMessage,
  showError
} from 'shared';
import { useUploadFile } from 'entities/file';
import SideBar from './SideBar';
import { detectFace } from 'features/upload-photo/model';

type UploadPhotoFlowProps = {
  onClose: () => void;
  imgUrl?: string | null;
};

const initialFileData: UploadedPhotoFileData = {
  name: '',
  size: '',
  data: null
};

const initialPhotoDetails: UploadedPhotoDetails = {
  inputImageUrl: '',
  gender: 'Male',
  name: '',
  age: null
};

export const UploadPhotoFlow: React.FC<UploadPhotoFlowProps> = ({
  onClose,
  imgUrl
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [uploadedPhotoDetails, setUploadedPhotoDetails] =
    useState<UploadedPhotoDetails>({
      ...initialPhotoDetails
    });
  const { uploadFileWithProgress } = useUploadFile();
  const [hasUploadStarted, setHasUploadStarted] = useState(false);
  const [fileData, setFileData] = useState(initialFileData);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isFaceChecking, setIsFaceChecking] = useState(false);
  const handleUpdatePhotoDetails = useCallback(
    (values: UploadedPhotoDetails) => {
      setUploadedPhotoDetails(values);
    },
    []
  );

  const [imageSize, setImageSize] = useState({ width: null, height: null });

  const startUpload = async (file: File) => {
    setHasUploadStarted(true);
    setFileData({ name: file.name, size: (file.size / 1024).toFixed() });
    const data = await uploadFileWithProgress(file, setUploadProgress);
    setFileData((prevState) => ({ ...prevState, data }));
    setUploadedPhotoDetails((prevState) => ({
      ...prevState,
      inputImageUrl: data.mediaLink
    }));
  };

  const handlePhotoUpload = useCallback(
    async (file: File) => {
      if (file.size > 5 * 1024 * 1024)
        return showError('Talking photo size must be less than 5mb.');
      try {
        setIsFaceChecking(true);
        const data = await detectFace(file);
        if (!data) {
          setIsFaceChecking(false);
          return;
        }
        if (!data.isValid || (data.isValid && data.count > 1)) {
          showError(
            'Please try another photo. No face recognized or there are too many people'
          );
          setIsFaceChecking(false);
          return;
        }
        setIsFaceChecking(false);
      } catch (error) {
        setIsFaceChecking(false);
        const message = getErrorMessage(
          error,
          `There was a problem when we tried to detect face. Please try again later.`
        );
        showError(message);
        showError((error as Error).message);
        return;
      }
      // Check photo min size
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        const img = new Image();
        // @ts-ignore
        img.src = e.target?.result;
        img.onload = function () {
          // @ts-ignore
          const height = this.height;
          // @ts-ignore
          const width = this.width;
          if (height < 256 || width < 256) {
            handleDeletePhoto();
            showError('Please upload a photo min 256x256 pixels');
          } else {
            startUpload(file);
          }
        };
      };
    },
    [setFileData, setHasUploadStarted]
  );

  const handleDeletePhoto = useCallback(() => {
    setFileData(initialFileData);
    setHasUploadStarted(false);
    setUploadedPhotoDetails(initialPhotoDetails);
  }, [setFileData, setHasUploadStarted, setUploadedPhotoDetails]);

  useEffect(() => {
    if (imgUrl) {
      setUploadedPhotoDetails((prevState) => ({
        ...prevState,
        inputImageUrl: imgUrl
      }));
      setCurrentStep(2);
    }
  }, []);

  const renderSteps = (currentStep: number) => {
    switch (currentStep) {
      case 0:
        return <Step0 onSetStep={setCurrentStep} />;
      case 1:
        return (
          <Step1
            onSetStep={setCurrentStep}
            uploadProgress={uploadProgress}
            uploadedPhoto={fileData.data?.mediaLink}
            hasUploadStarted={hasUploadStarted}
            onDeleteUploaded={handleDeletePhoto}
            onFileUpload={handlePhotoUpload}
            fileData={fileData}
            isFaceChecking={isFaceChecking}
          />
        );
      case 2:
        return (
          <Step2
            onSetStep={setCurrentStep}
            uploadedPhotoDetails={uploadedPhotoDetails}
            onUpdateDetails={handleUpdatePhotoDetails}
            hideBackButton={Boolean(imgUrl)}
          />
        );
      case 3:
        return (
          <Step3
            onSetStep={setCurrentStep}
            uploadedPhoto={fileData.data?.mediaLink || imgUrl}
            uploadedPhotoDetails={uploadedPhotoDetails}
            onCloseModal={onClose}
          />
        );
    }
  };

  return (
    <div className="yep_ex-relative yep_ex-w-full md:yep_ex-flex md:yep_ex-min-h-[500px] md:yep_ex-overflow-hidden">
      {/* <svg
        onClick={onClose}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="yep_ex-w-4 yep_ex-h-4 yep_ex-absolute yep_ex-top-4 yep_ex-right-4 md:yep_ex-top-6 md:yep_ex-right-6 yep_ex-cursor-pointer yep_ex-z-10 yep_ex-text-neutral-400 md:yep_ex-text-black"
      >
        <path
          d="M1.60938 14.846L14.453 1.8418M1.60938 1.8418L14.453 14.846"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg> */}

      <SideBar currentStep={currentStep} />

      <div className="sm:yep_ex-relative yep_ex-pt-4 sm:yep_ex-pt-8 yep_ex-pl-4 md:yep_ex-p-6 yep_ex-pr-6 yep_ex-pb-32 sm:yep_ex-pb-20 yep_ex-w-full yep_ex-overflow-y-auto yep_ex-max-h-[80vh] sm:yep_ex-max-h-none">
        {renderSteps(currentStep)}
      </div>
    </div>
  );
};

import { StateCreator } from 'zustand';
import { Store } from 'App/store';
import { IPlan, IUser, getAuthToken } from 'shared';
import { authApi } from '../api';
import { toast } from 'react-toastify';

type AuthState = {
  user: IUser | null;
  plan: IPlan | null;
  isCreateAccModalOpened: boolean;
};

type AuthActions = {
  me: () => Promise<void>;
  getCurrentPlan: () => Promise<void>;
  regenerateApiKey: () => Promise<void>;
  toggleCreateAccModal: () => void;
  updateAuthStore: (key: string, value: any) => void;
};

export type AuthSlice = AuthState & AuthActions;

export const authStoreSlice: StateCreator<Store, [], [], AuthSlice> = (
  set,
  get
) => ({
  user: null,
  isStarter: false,
  plan: null,
  isCreateAccModalOpened: false,
  updateAuthStore: (key, value) => {
    set({ [key]: value });
  },
  toggleCreateAccModal: () => {},
  me: async () => {
    try {
      const user = await authApi.me();

      set({
        user
      });
    } catch (error) {
      console.error(error);
    }
  },
  getCurrentPlan: async () => {
    try {
      const {
        data: [plan]
      } = await authApi.getCurrentPlan();
      set({ plan });
    } catch (error) {
      if (error instanceof Error) {
        toast.error(
          `Unable to get plan. Please try again later. Error: ${error.message}`
        );
      }
    }
  },
  regenerateApiKey: async () => {
    await authApi.regenerateApiKey();
    await get().me();
  }
});

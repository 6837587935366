import React from 'react';
import { BsFillCheckCircleFill } from 'react-icons/bs';

type SideBarProps = {
  currentStep: number | null;
  showSupportButton?: boolean;
};

const SideBar: React.FC<SideBarProps> = ({
  currentStep,
  showSupportButton = false
}) => {
  return (
    <div
      className="md:yep_ex-w-[300px] yep_ex-bg-dark-primary yep_ex-relative yep_ex-pt-4 sm:yep_ex-pl-3 yep_ex-rounded-tl-lg yep_ex-rounded-tr-lg 
    md:yep_ex-rounded-tr-none md:yep_ex-rounded-bl-lg "
    >
      <div className="yep_ex-px-4 sm:yep_ex-px-2">
        {/* <img
          src="../../../../shared/resourses/img/logo.png"
          alt="logo"
          className="inline-block shrink-0 w-8 xl:w-12 mr-2"
        /> */}
        <span className="yep_ex-text-xs yep_ex-text-gray-400">
          My Talking Photos
        </span>
      </div>
      {(currentStep || currentStep === 0) && (
        <div
          className="yep_ex-flex yep_ex-justify-center md:yep_ex-block yep_ex-text-xs md:yep_ex-text-base yep_ex-mt-4 md:yep_ex-mt-11 sm:yep_ex-ml-2 yep_ex-text-gray-400 
        yep_ex-pb-4 yep_ex-relative"
        >
          <div
            className={`yep_ex-mb-1 md:yep_ex-mb-4 yep_ex-mr-3 md:yep_ex-mr-0 ${
              currentStep > 0 && 'yep_ex-text-primary-600'
            }`}
          >
            {currentStep > 1 ? (
              <BsFillCheckCircleFill className="yep_ex-inline-block yep_ex-mr-1 md:yep_ex-mr-2 yep_ex-text-primary-600" />
            ) : (
              <span className="yep_ex-mr-2 yep_ex-inline-block yep_ex-w-3">
                1.
              </span>
            )}
            Upload photo
          </div>
          <div
            className={`yep_ex-mb-1 md:yep_ex-mb-4 yep_ex-mr-3 md:yep_ex-mr-0 ${
              currentStep > 1 && 'yep_ex-text-primary-600'
            }`}
          >
            {currentStep > 2 ? (
              <BsFillCheckCircleFill className="yep_ex-inline-block yep_ex-mr-1 md:yep_ex-mr-2 yep_ex-text-primary-600" />
            ) : (
              <span className="yep_ex-mr-2 yep_ex-inline-block yep_ex-w-3">
                2.
              </span>
            )}
            Details
          </div>
          <div className={`${currentStep > 2 && 'yep_ex-text-primary-600'}`}>
            <span className="yep_ex-mr-1 md:yep_ex-mr-2 yep_ex-inline-block yep_ex-w-3">
              3.
            </span>{' '}
            Review
          </div>
        </div>
      )}
      {showSupportButton && (
        <button
          className="yep_ex-underline yep_ex-text-gray-400 yep_ex-absolute yep_ex-hidden sm:yep_ex-block yep_ex-bottom-5 yep_ex-right-3 md:yep_ex-bottom-3 
          md:yep_ex-right-1/2 yep_ex-text-sm yep_ex-opacity-80 hover:yep_ex-opacity-100"
          // @ts-ignore
          onClick={() => window.HubSpotConversations.widget.open()}
        >
          Need help?
        </button>
      )}
    </div>
  );
};

export default SideBar;

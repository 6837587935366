import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  UploadedPhotoDetails,
  Loader,
  PrimaryButton,
  firebaseAnalytics,
  showSuccessMessage,
  showError
} from 'shared';
import { avatarStore } from 'entities/avatar';
import { logEvent } from 'firebase/analytics';
import * as amplitude from '@amplitude/analytics-browser';

type Step4Props = {
  onSetStep: Dispatch<SetStateAction<number>>;
  uploadedPhoto: string;
  uploadedPhotoDetails: UploadedPhotoDetails;
  onCloseModal: () => void;
};

const Step4: React.FC<Step4Props> = ({
  onSetStep,
  uploadedPhoto,
  uploadedPhotoDetails,
  onCloseModal
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const addUploadedPhoto = avatarStore((state) => state.addUploadedPhoto);

  const handleCreatePhoto = async () => {
    setIsLoading(true);
    const success = await addUploadedPhoto(uploadedPhotoDetails);
    if (success) {
      onCloseModal();
      setIsLoading(false);
      showSuccessMessage('You have succesfully created avatar');
      amplitude.track('express_upload_talking_photo');
      logEvent(firebaseAnalytics, 'express_upload_talking_photo');
    } else {
      setIsLoading(false);
      showError('Something went wrong while creating photo');
    }
  };

  return (
    <>
      <h1 className="yep_ex-text-lg yep_ex-font-semibold yep_ex-mb-2">
        Review
      </h1>
      <p className="yep_ex-text-neutral-400 yep_ex-mb-8">
        Review and create your talking photo.
      </p>
      <div
        className={`yep_ex-text-center yep_ex-rounded-xl yep_ex-p-4 yep_ex-border yep_ex-text-neutral-400 yep_ex-border-gray-200`}
      >
        <div
          className="yep_ex-border yep_ex-border-gray-100 yep_ex-rounded yep_ex-relative yep_ex-shrink-0 yep_ex-flex
        yep_ex-items-center yep_ex-flex-col yep_ex-justify-center yep_ex-overflow-hidden yep_ex-bg-gray-50 "
        >
          {uploadedPhoto && (
            <img
              src={uploadedPhoto}
              alt="Uploaded photo"
              className="yep_ex-w-full yep_ex-h-full yep_ex-object-contain yep_ex-z-10 yep_ex-max-h-44"
            />
          )}
        </div>
        <div className="yep_ex-flex yep_ex-gap-4 yep_ex-mt-2 yep_ex-items-baseline">
          <h2 className="yep_ex-text-base yep_ex-font-semibold yep_ex-text-gray-900">
            {uploadedPhotoDetails.name}
          </h2>
          <span className="yep_ex-text-gray-600 yep_ex-font-normal yep_ex-text-sm">
            {uploadedPhotoDetails.age}
            {uploadedPhotoDetails.gender && `, ${uploadedPhotoDetails.gender}`}
          </span>
        </div>
      </div>
      <div
        className="yep_ex-absolute yep_ex-flex yep_ex-flex-col-reverse sm:yep_ex-flex-row yep_ex-gap-2 yep_ex-mt-4 yep_ex-justify-between yep_ex-items-center
        yep_ex-bottom-0 yep_ex-left-0 sm:yep_ex-left-6 sm:yep_ex-right-4 sm:yep_ex-bottom-4 yep_ex-bg-white yep_ex-z-20 sm:yep_ex-z-auto yep_ex-w-full sm:yep_ex-w-auto yep_ex-py-4 sm:yep_ex-py-0"
      >
        <button
          onClick={() => onSetStep((prevState) => prevState - 1)}
          className={`yep_ex-bg-white yep_ex-border yep_ex-border-black yep_ex-text-sm yep_ex-font-semibold yep_ex-rounded yep_ex-py-2.5 yep_ex-px-16 yep_ex-min-w-[180px]`}
        >
          Back
        </button>
        <PrimaryButton
          onClick={handleCreatePhoto}
          className="yep_ex-bg-secondary yep_ex-border yep_ex-border-secondary yep_ex-text-white yep_ex-text-sm yep_ex-font-semibold yep_ex-rounded yep_ex-py-2.5 yep_ex-px-16 yep_ex-min-w-[180px] yep_ex-flex yep_ex-justify-center yep_ex-items-center"
        >
          {isLoading ? <Loader size={18} color="#ffffff" /> : 'Create'}
        </PrimaryButton>
      </div>
    </>
  );
};

export default Step4;

import { Loader, PrimaryButton } from 'shared';
import { GenerateVideoPopup } from './GenerateVideoPopup';
import { useGenerateVideoModel } from '../model';
import { appStore } from 'App/store';
import { useState } from 'react';
import { creditStore } from 'entities/credit';

export const GenerateVideo = () => {
  const { user, toggleCreateAccModal } = appStore();
  const [isLoading, setLoading] = useState(false);
  const [draftId, setDraftId] = useState<string | null>(null);
  const {
    credits,
    toggleBuyCreditsModal,
    setEstimatedVideoPrice,
    planFeatures,
    toggleFeatureBlockModal
  } = creditStore();
  const [estimate, setEstimate] = useState<number | null>(null);
  const { getEstimate } = useGenerateVideoModel();

  const [isGeneratePopupOpened, setIsGeneratePopupOpened] = useState(false);

  const getEstimatedCredits = async () => {
    setLoading(true);
    setEstimate(null);

    const { totalCredits, id } = await getEstimate();

    if (totalCredits > credits) {
      setEstimatedVideoPrice(totalCredits);
      toggleBuyCreditsModal();
    } else {
      setEstimate(totalCredits);
      setIsGeneratePopupOpened(true);
    }

    setDraftId(id);
    setLoading(false);
  };

  const { validateVideo } = useGenerateVideoModel();
  return (
    <>
      <PrimaryButton
        // className="yep_ex-w-24 yep_ex-flex yep_ex-items-center yep_ex-justify-center"
        className="yep_ex-px-2 yep_ex-py-1 sm:yep_ex-px-2.5 sm:yep_ex-py-2 yep_ex-rounded-lg yep_ex-text-white yep_ex-min-w-[6rem] sm:yep_ex-min-w-[8rem] yep_ex-flex yep_ex-justify-center yep_ex-items-center yep_ex-mx-auto yep_ex-border yep_ex-bg-primary-600 yep_ex-gap-1 yep_ex-font-semibold"
        onClick={() => {
          if (!user) {
            toggleCreateAccModal();
            return;
          }
          if (planFeatures?.expressAllowed) {
            if (validateVideo()) getEstimatedCredits();
          } else {
            toggleFeatureBlockModal('express');
          }
        }}
      >
        {isLoading ? (
          <Loader size={20} color="#fff" />
        ) : (
          <>
            {user && !planFeatures?.expressAllowed && (
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3 5.66669C3 2.90526 5.23858 0.666687 8 0.666687C10.7614 0.666687 13 2.90526 13 5.66669V6.62529C13.2355 6.6769 13.4615 6.75204 13.68 6.86333C14.3072 7.18291 14.8171 7.69285 15.1367 8.32005C15.3428 8.7245 15.4249 9.15509 15.4632 9.6235C15.5 10.0745 15.5 10.6281 15.5 11.2989V12.5345C15.5 13.2053 15.5 13.7589 15.4632 14.2099C15.4249 14.6783 15.3428 15.1089 15.1367 15.5133C14.8171 16.1405 14.3072 16.6505 13.68 16.97C13.2755 17.1761 12.8449 17.2583 12.3765 17.2965C11.9255 17.3334 11.3719 17.3334 10.7011 17.3334H5.29894C4.62812 17.3334 4.07448 17.3334 3.62348 17.2965C3.15507 17.2583 2.72448 17.1761 2.32003 16.97C1.69283 16.6505 1.18289 16.1405 0.863313 15.5133C0.657237 15.1089 0.5751 14.6783 0.536829 14.2099C0.499982 13.7589 0.49999 13.2052 0.5 12.5345V11.2989C0.49999 10.6281 0.499982 10.0745 0.536829 9.6235C0.5751 9.15508 0.657237 8.7245 0.863313 8.32005C1.18289 7.69285 1.69283 7.18291 2.32003 6.86333C2.53845 6.75204 2.76449 6.6769 3 6.62529V5.66669ZM11.3333 5.66669V6.50138H4.66667V5.66669C4.66667 3.82574 6.15905 2.33335 8 2.33335C9.84095 2.33335 11.3333 3.82574 11.3333 5.66669ZM8.83333 11.0834C8.83333 10.6231 8.46024 10.25 8 10.25C7.53976 10.25 7.16667 10.6231 7.16667 11.0834V12.75C7.16667 13.2103 7.53976 13.5834 8 13.5834C8.46024 13.5834 8.83333 13.2103 8.83333 12.75V11.0834Z"
                  fill="white"
                />
              </svg>
            )}
            <span>Generate</span>
          </>
        )}
      </PrimaryButton>
      <GenerateVideoPopup
        loading={isLoading}
        draftId={draftId}
        estimate={estimate}
        opened={isGeneratePopupOpened}
        setOpened={setIsGeneratePopupOpened}
      />
    </>
  );
};

import { logEvent } from 'firebase/analytics';
import * as amplitude from '@amplitude/analytics-browser';
import clsx from 'clsx';
import { useUploadFile } from 'entities/file';
import { videoStore } from 'entities/video';
import MicRecorder from 'mic-recorder-to-mp3';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { HiOutlineMicrophone } from 'react-icons/hi';
import {
  Loader,
  OutlineButton,
  addZero,
  firebaseAnalytics,
  showError,
  showMessage
} from 'shared';

type Props = {
  setIsRecord: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
};

export const RecordVoiceover = ({ setIsRecord, onClose }: Props) => {
  const [time, setTime] = useState(0);

  const { setVoiceover } = videoStore();
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorder = useRef<MicRecorder | null>(null);
  const startRecordTime = useRef<number>(0);
  const timeInterval = useRef<number | null>(null);
  const stopTimeout = useRef<number | null>(null);
  const { uploadFile } = useUploadFile();
  const [isLoading, setIsLoading] = useState(false);

  const handleStopRecord = async () => {
    setIsRecording(false);
    if (mediaRecorder.current) {
      setIsLoading(true);
      if (timeInterval.current) clearInterval(timeInterval.current);
      if (stopTimeout.current) clearTimeout(stopTimeout.current);
      const [_, blob] = await mediaRecorder.current.stop().getMp3();

      const file: File = new File([blob], 'voiceover.mp3', {
        lastModified: +new Date(),
        type: 'audio/mp3'
      });

      const data = await uploadFile(file);
      const audio = new Audio(data.mediaLink);
      audio.setAttribute('preload', 'metadata');
      await new Promise<void>((resolve) => {
        audio.onloadedmetadata = () => {
          resolve();
        };
      });

      setVoiceover({
        name: file.name,
        link: data.mediaLink,
        size: Math.floor(file.size / 1024),
        audio
      });
      amplitude.track('express_voiceover_record');
      logEvent(firebaseAnalytics, 'express_voiceover_record');
      mediaRecorder.current = null;
      setIsLoading(false);
      onClose();
    }
  };

  const handleRecord = () => {
    if (isRecording) {
      return handleStopRecord();
    }
    try {
      mediaRecorder.current = new MicRecorder();
      setIsRecording(true);
      mediaRecorder.current.start().then(() => {
        startRecordTime.current = +new Date();
        setTime(0);
        stopTimeout.current = setTimeout(() => {
          showError("Voiceover's length must be less than 4 minutes");
          if (mediaRecorder.current || isRecording) {
            handleStopRecord();
          }
        }, 4 * 60 * 1000) as unknown as number;

        timeInterval.current = setInterval(() => {
          const duration = (+new Date() - startRecordTime.current) / 1000;
          if (duration >= 240) {
            showError("Voiceover's length must be less than 4 minutes");
            if (timeInterval.current) clearInterval(timeInterval.current);
            return handleStopRecord();
          }
          setTime(duration);
        }, 100) as unknown as number;
      });
    } catch (e) {
      showMessage('Record not supported on your browser');
    }
  };

  return (
    <div className="yep_ex-py-4 sm:yep_ex-px-4 yep_ex-flex yep_ex-flex-col yep_ex-gap-4 yep_ex-items-center">
      <div className="yep_ex-flex yep_ex-flex-col yep_ex-items-center yep_ex-gap-2">
        <h2 className="yep_ex-pb-6 yep_ex-text-xl yep_ex-font-semibold yep_ex-text-neutral-900">
          Audio recording
        </h2>
        <button
          onClick={handleRecord}
          className={clsx(
            'yep_ex-w-20 yep_ex-aspect-square yep_ex-font-medium yep_ex-px-4 yep_ex-py-2.5 yep_ex-border yep_ex-rounded-full yep_ex-text-white yep_ex-flex yep_ex-justify-center yep_ex-items-center',
            isRecording
              ? 'yep_ex-bg-red-600 yep_ex-border-red-600 hover:yep_ex-bg-red-700 hover:yep_ex-border-red-700'
              : 'yep_ex-bg-primary-600 yep_ex-border-primary-600 hover:yep_ex-bg-primary-700 hover:yep_ex-border-primary-700'
          )}
        >
          {isRecording && (
            <div className="yep_ex-aspect-square yep_ex-bg-white yep_ex-rounded-sm yep_ex-w-[1.6875rem] " />
          )}
          {isLoading && <Loader color={'#fff'} size={27} />}
          {!isRecording && !isLoading && (
            <HiOutlineMicrophone className="yep_ex-text-xl yep_ex-w-full yep_ex-h-full" />
          )}
        </button>
        <span className="yep_ex-text-xs yep_ex-font-medium yep_ex-text-neutral-500">
          Record
        </span>
      </div>
      <div className="yep_ex-flex yep_ex-flex-col yep_ex-items-center yep_ex-gap-2 yep_ex-text-neutral-500 yep_ex-py-10 yep_ex-px-1 yep_ex-w-full sm:yep_ex-w-[32rem] yep_ex-border yep_ex-rounded-xl yep_ex-border-neutral-200 yep_ex-text-center">
        <span className="yep_ex-text-3xl yep_ex-font-medium">
          {addZero((time - (time % 60)) / 60)}:
          {addZero(Math.floor(+(time % 60)))}
        </span>
        <span className="yep_ex-text-sm">
          Record up to 5 minutes in a quiet space
        </span>
      </div>
      <OutlineButton onClick={() => setIsRecord(false)}>Cancel</OutlineButton>
    </div>
  );
};

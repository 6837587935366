import { useEffect, useState } from 'react';
import { videoStore } from 'entities/video';
import { featureFlags, showError } from 'shared';
import { ListenScript } from './ListenScript';
import { voiceStore } from 'entities/voice';
import { checkLengthError } from '../lib';
import { ChatGPTButton, ChatGptModal } from 'features/chat-gpt';
import { appStore } from 'App/store';

export const Script = () => {
  const { scriptText, setScriptText, voiceover } = videoStore();
  const { currentVoice } = voiceStore();

  const handleInput = (text: string) => {
    setScriptText(text);
  };

  useEffect(() => {
    checkLengthError(
      scriptText?.length ?? 0,
      currentVoice?.maxCharacterLimit
        ? Number(currentVoice?.maxCharacterLimit)
        : 4000,
      showError
    );
  }, [scriptText, voiceover]);

  return (
    <div className="yep_ex-flex yep_ex-flex-col">
      {featureFlags.chatgpt && (
        <div className="yep_ex-hidden lg:yep_ex-block">
          <ChatGPTButton />
        </div>
      )}
      <textarea
        value={
          voiceover
            ? 'Voiceover is uploaded. If you want to use script and synthetic voice, please remove voiceover'
            : scriptText
        }
        disabled={Boolean(voiceover)}
        onChange={(e) => handleInput(e.target.value)}
        className="yep_ex-border-0 yep_ex-bg-neutral-50 yep_ex-w-full yep_ex-min-h-[10rem] yep_ex-rounded-md focus:yep_ex-outline-primary-600 focus:yep_ex-ring-0 disabled:yep_ex-text-gray-400"
        placeholder={
          featureFlags.chatgpt
            ? 'Type your script here, or click the chat GPT logo above for help generating your first video'
            : 'Type your script here...'
        }
      >
        Hello
      </textarea>
      <div className="yep_ex-flex yep_ex-justify-end yep_ex-items-center yep_ex-mb-3 xl:yep_ex-mb-6 yep_ex-text-sm">
        {!voiceover && (
          <>
            <span
              className={`${
                scriptText?.length >
                Number(currentVoice?.maxCharacterLimit ?? 4000)
                  ? 'yep_ex-text-red-500'
                  : ''
              }`}
            >
              {scriptText?.length}
            </span>
            /{currentVoice?.maxCharacterLimit ?? 4000}
          </>
        )}
      </div>
      <ListenScript />
    </div>
  );
};

import { GenderOptions, IAvatar } from 'shared';

export const filterAvatars = (
  avatars: IAvatar[],
  genderFilters: GenderOptions[]
) => {
  return avatars
    .filter(
      (avatar) =>
        !genderFilters.length ||
        genderFilters.includes(avatar.gender?.toLowerCase() as GenderOptions)
    )
    .sort((a, b) => (a.name > b.name ? 1 : -1));
};

import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { BASEURL_DOTNET } from '../config';
import { getAuthToken, getCookieValue, setCookie } from 'shared';

export const httpService = axios.create({
  baseURL: BASEURL_DOTNET,
  headers: {
    'Content-Type': 'application/json'
  }
});

httpService.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const refreshToken = async () => {
  const oldRefreshToken = getCookieValue('refresh_token');
  if (!oldRefreshToken) return null;
  const {
    data: {
      accessToken,
      accessTokenExpiresOn,
      refreshTokenExpiresOn,
      refreshToken
    }
  } = await httpService.post('/users/token/refresh', {
    refreshToken: oldRefreshToken
  });

  setCookie('access_token', accessToken, accessTokenExpiresOn);
  setCookie('refresh_token', refreshToken, refreshTokenExpiresOn);

  return accessToken;
};

httpService.interceptors.response.use(
  (res) => res,
  async (error) => {
    const originalRequest: InternalAxiosRequestConfig & { _retry: boolean } =
      error.config;
    if (error instanceof AxiosError) {
      // if (error.response?.status === 401) {
      //   if (originalRequest._retry) {
      //     setCookie('refresh_token', '', 0);
      //     setCookie('access_token', '', 0);
      //     window.location.href = './';
      //   } else {
      //     originalRequest._retry = true;
      //     const token = await refreshToken();
      //     originalRequest.headers.Authorization = `Bearer ${token}`;
      //     return httpService(originalRequest);
      //   }
      // }
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { avatarStore } from 'entities/avatar';
import {
  IAvatar,
  Loader,
  Modal,
  OutlineButton,
  PrimaryButton,
  capitalize,
  getErrorMessage,
  showError
} from 'shared';
import { BsTrash } from 'react-icons/bs';

type CustomTalkingPhotoInfoProps = {
  onClose: () => void;
  avatar: IAvatar;
};

const CustomTalkingPhotoInfo = ({
  onClose,
  avatar
}: CustomTalkingPhotoInfoProps) => {
  const imgUrl =
    avatar.inputImageUrl || avatar.menuImageUrl || avatar.rawImageUrl;

  const { deleteCustomTalkingPhoto } = avatarStore();

  const [isDeleteConfirmationOpened, setIsDeleteConfirmationOpened] =
    useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeletePhoto = async (id: string) => {
    setIsDeleting(true);
    try {
      const data = await deleteCustomTalkingPhoto(id);
      if (data) {
        toast.success('Custom photo deleted successfully');
      }
    } catch (err) {
      console.error(err);
      const message = getErrorMessage(
        err,
        `We couldn't delete photo. Please try again later`
      );
      showError(message);
    } finally {
      setIsDeleting(false);
      setIsDeleteConfirmationOpened(false);
      onClose();
    }
  };

  return (
    <div className="yep_ex-relative yep_ex-w-full md:yep_ex-flex md:yep_ex-overflow-hidden">
      <div className="yep_ex-py-4 yep_ex-pl-4 md:yep_ex-pl-6 yep_ex-pr-4 yep_ex-w-full yep_ex-relative ">
        <h3 className="yep_ex-text-lg yep_ex-font-inter yep_ex-font-semibold yep_ex-text-gray-900 yep_ex-text-left yep_ex-mb-4">
          Custom photo
        </h3>
        <div className="yep_ex-flex yep_ex-flex-col yep_ex-items-start yep_ex-gap-4 yep_ex-py-6 yep_ex-border-y yep_ex-border-y-neutral-200">
          {imgUrl && (
            <img
              crossOrigin="anonymous"
              className={`yep_ex-object-contain yep_ex-rounded-lg yep_ex-bg-neutral-50 yep_ex-max-h-72 yep_ex-min-w-full`}
              src={imgUrl}
              alt={avatar.name}
            />
          )}

          <div className="yep_ex-flex yep_ex-mt-4 yep_ex-gap-6">
            <div>
              <p className="yep_ex-text-gray-500 yep_ex-font-semibold yep_ex-text-xs">
                Name
              </p>
              <p className="yep_ex-text-sm yep_ex-text-neutral-800 yep_ex-font-semibold">
                {avatar.name}
              </p>
            </div>

            <div>
              <p className="yep_ex-text-gray-500 yep_ex-font-semibold yep_ex-text-xs">
                Age
              </p>
              <p className="yep_ex-text-sm yep_ex-text-neutral-800 yep_ex-font-semibold">
                {avatar.age}
              </p>
            </div>
            <div>
              <p className="yep_ex-text-gray-500 yep_ex-font-semibold yep_ex-text-xs">
                Gender
              </p>
              <p className="yep_ex-text-sm yep_ex-text-neutral-800 yep_ex-font-semibold">
                {avatar.gender}
              </p>
            </div>
          </div>
        </div>
        <OutlineButton
          onClick={() => setIsDeleteConfirmationOpened(true)}
          className="yep_ex-font-semibold yep_ex-flex yep_ex-justify-center yep_ex-items-center yep_ex-border-none
                yep_ex-text-red-700 yep_ex-mt-8 hover:yep_ex-border hover:yep_ex-border-red-600 yep_ex-px-0"
        >
          <BsTrash className="yep_ex-mr-2 yep_ex-h-4 yep_ex-w-4" />
          Delete photo
        </OutlineButton>
      </div>
      {isDeleteConfirmationOpened && (
        <Modal
          open={isDeleteConfirmationOpened}
          onClose={() => setIsDeleteConfirmationOpened(false)}
          withCloseButton
          className="md:yep_ex-max-w-[400px]"
        >
          <h3 className="yep_ex-text-lg yep_ex-font-inter yep_ex-font-semibold yep_ex-text-gray-900">
            Delete Talking Photo
          </h3>
          <span className="yep_ex-text-gray-600 yep_ex-text-sm">
            Are you sure you want to delete this Talking Photo? This action
            cannot be undone.
          </span>
          <div className="yep_ex-flex yep_ex-justify-between yep_ex-items-center yep_ex-gap-3 yep_ex-mt-8">
            <OutlineButton
              className="yep_ex-w-1/2 focus:yep_ex-outline-primary-600"
              onClick={() => setIsDeleteConfirmationOpened(false)}
            >
              Cancel
            </OutlineButton>
            <PrimaryButton
              type="button"
              className="yep_ex-w-1/2 focus:yep_ex-outline-white yep_ex-flex yep_ex-justify-center yep_ex-items-center yep_ex-bg-red-600 hover:yep_ex-bg-red-700"
              onClick={() => handleDeletePhoto(avatar.id)}
            >
              {isDeleting ? <Loader size={20} color="#ffffff" /> : 'Delete'}
            </PrimaryButton>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default CustomTalkingPhotoInfo;

import clsx from 'clsx';
import React, { MouseEvent, useEffect, useState } from 'react';
import { BsGenderFemale, BsGenderMale } from 'react-icons/bs';
import { FaStop } from 'react-icons/fa';
import { IVoice, capitalize, featureFlags, showWarn } from 'shared';
import { voiceStore } from '../model';
import { ReactComponent as Premium } from './svg/premium.svg';
import { appStore } from 'App/store';
import { creditStore } from 'entities/credit';

type VoiceProps = {
  voice: IVoice;
  onPlay: (audio: HTMLAudioElement, fromStart: boolean) => void;
  onPause: () => void;
  isPlaying: boolean;
  currentAudio: HTMLAudioElement | null;
  doesGenderMatch?: boolean;
};

export const Voice = ({
  voice,
  onPlay,
  onPause,
  isPlaying,
  currentAudio,
  doesGenderMatch
}: VoiceProps) => {
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const { currentVoice, setCurrentVoice } = voiceStore();
  const { user, toggleCreateAccModal } = appStore();
  const { planFeatures, toggleFeatureBlockModal } = creditStore();

  useEffect(() => {
    if (voice.audioSampleUrl) {
      const newAudio = new Audio(voice.audioSampleUrl);
      newAudio.load();
      setAudio(newAudio);
    }
  }, []);

  const handlePlay = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (audio) {
      onPlay(audio, true);
    }

    if (isPlaying && currentAudio === audio) {
      onPause();
      return;
    }
  };

  const handlePlayNotification = () => {
    showWarn(
      "We're sorry, but at the moment voice preview is disabled due to technical problems."
    );
  };

  const handleChooseVoice = async () => {
    if (voice.isPremiumVoice) {
      if (!planFeatures?.premiumVoicesAllowed) {
        if (!user) return toggleCreateAccModal();
        return toggleFeatureBlockModal('premiumVoices');
      }
    }

    setCurrentVoice(voice);
  };

  return (
    <div
      className={clsx(
        `yep_ex-flex yep_ex-items-center yep_ex-justify-between yep_ex-p-1 yep_ex-py-3 yep_ex-w-full yep_ex-border yep_ex-text-neutral-600 
        yep_ex-text-sm disabled:yep_ex-opacity-50 yep_ex-rounded-lg hover:yep_ex-border-primary-600`,
        currentVoice?.id === voice.id
          ? 'yep_ex-border-primary-600'
          : 'yep_ex-border-neutral-200 yep_ex-cursor-pointer'
      )}
      onClick={handleChooseVoice}
    >
      <div className="yep_ex-flex yep_ex-items-center yep_ex-space-x-2 yep_ex-w-full">
        <button
          className="yep_ex-inline-flex yep_ex-items-center yep_ex-justify-center yep_ex-rounded-lg yep_ex-pl-2.5"
          onClick={voice.audioSampleUrl ? handlePlay : handlePlayNotification}
        >
          {isPlaying && currentAudio === audio ? (
            <FaStop className="yep_ex-w-[11px] yep_ex-h-[14px] yep_ex-text-secondary" />
          ) : (
            <svg
              width="11"
              height="14"
              viewBox="0 0 11 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.525 13.0258C1.19167 13.2425 0.854333 13.2552 0.513 13.0638C0.171666 12.8725 0.000666667 12.5765 0 12.1758V1.82582C0 1.42582 0.171 1.12982 0.513 0.937822C0.855 0.745822 1.19233 0.758489 1.525 0.975822L9.675 6.15082C9.975 6.35082 10.125 6.63416 10.125 7.00082C10.125 7.36749 9.975 7.65082 9.675 7.85082L1.525 13.0258Z"
                fill="#C34DFF"
              />
            </svg>
          )}
        </button>
        <div
          title={
            !doesGenderMatch
              ? "This voice doesn't match chosen actor's gender"
              : ''
          }
          className={clsx(doesGenderMatch && 'text-red-400')}
        >
          {voice?.gender === 'male' ? (
            <BsGenderMale className={`yep_ex-inline-block yep_ex-text-lg`} />
          ) : (
            <BsGenderFemale className={`yep_ex-inline-block yep_ex-text-lg `} />
          )}
        </div>
        <div className="yep_ex-text-left yep_ex-w-[70%] yep_ex-inline-flex">
          <span className="yep_ex-text-sm yep_ex-mr-2 yep_ex-font-medium yep_ex-w-fit">
            {capitalize(voice.name)}
          </span>
          <span className="yep_ex-text-sm yep_ex-overflow-hidden yep_ex-w-full yep_ex-text-ellipsis yep_ex-whitespace-nowrap yep_ex-inline-block">
            {voice.language}
          </span>
        </div>
        {voice.isPremiumVoice && <Premium />}
      </div>
    </div>
  );
};

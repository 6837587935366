import { BsFilter } from 'react-icons/bs';
import { voiceStore } from 'entities/voice';
import { Checkbox, Divider, Dropdown, GenderOptions } from 'shared';
import SelectInput from 'shared/ui/SelectInput';
import LanguageSelect from './LanguageSelect';
import { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import { WaitlistForm } from '../../join-waitlist';
import clsx from 'clsx';

export const VoicesFilters = () => {
  const {
    genders,
    resetGenders,
    changeGender,
    providers,
    filters,
    setFilters,
    languages
  } = voiceStore();

  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [mobileLanguagesOpen, setMobileLanguagesOpen] = useState(false);
  const [showCustomVoiceModal, setShowCustomVoiceModal] = useState(false);

  const filtersLength =
    (filters.provider[0] === 'All providers' ? 0 : filters.provider.length) +
    genders.length;
  return (
    <>
      <div className="yep_ex-mx-4 yep_ex-z-20 yep_ex-hidden md:yep_ex-block">
        {languages && <LanguageSelect />}
        {providers && (
          <SelectInput
            name="providers"
            options={providers}
            className="yep_ex-text-xs yep_ex-mb-2 yep_ex-w-full"
            value={filters?.provider[0] ?? 'All providers'}
            onChange={(value: string) =>
              setFilters({
                ...filters,
                provider: [value ?? 'All providers']
              })
            }
          />
        )}
        <Dropdown
          label={`Filters${genders.length > 0 ? ` (${genders.length})` : ''}`}
          icon={<BsFilter className="yep_ex-w-5 yep_ex-h-5" />}
          className="yep_ex-relative yep_ex-my-6"
          buttonClassName={
            genders.length > 0
              ? 'yep_ex-text-primary-700 yep_ex-border-primary-300'
              : ''
          }
        >
          <div className="yep_ex-pt-5 yep_ex-pl-3.5 yep_ex-pr-4 yep_ex-pb-4">
            <div className="yep_ex-flex yep_ex-justify-between yep_ex-w-full yep_ex-items-baseline">
              <span className="yep_ex-text-sm yep_ex-font-inter yep_ex-font-semibold">
                Filter
              </span>
              <button
                className={`yep_ex-text-sm yep_ex-font-inter yep_ex-font-semibold ${
                  genders.length
                    ? 'yep_ex-text-secondary'
                    : 'yep_ex-text-gray-300 yep_ex-pointer-events-none'
                }`}
                onClick={resetGenders}
              >
                Reset
              </button>
            </div>
            <div className="yep_ex-flex yep_ex-flex-col yep_ex-items-start yep_ex-gap-4 yep_ex-justify-evenly yep_ex-mt-5">
              <Checkbox
                key="filter-male"
                label="Male"
                onClick={(value) => changeGender(value as GenderOptions)}
                choosenValues={genders}
                value="male"
              />
              <Checkbox
                key="filter-female"
                label="Female"
                onClick={(value) => changeGender(value as GenderOptions)}
                choosenValues={genders}
                value="female"
              />
            </div>
          </div>
        </Dropdown>
      </div>
      <div className="md:yep_ex-hidden yep_ex-py-2 yep_ex-flex yep_ex-gap-4 yep_ex-items-center yep_ex-px-4">
        <button
          className={clsx(
            `yep_ex-flex yep_ex-items-center yep_ex-gap-2 yep_ex-justify-center yep_ex-border yep_ex-rounded-lg yep_ex-py-2.5 yep_ex-px-4 yep_ex-w-32`,
            filtersLength > 0
              ? 'yep_ex-border-primary-400 yep_ex-text-primary-700'
              : 'yep_ex-border-gray-300 yep_ex-text-neutral-700'
          )}
          onClick={() => setMobileFiltersOpen(true)}
        >
          <span className="yep_ex-text-sm yep_ex-font-inter yep_ex-font-semibold">
            Filters{filtersLength > 0 ? ` (${filtersLength})` : ''}
          </span>
          <BsFilter className="yep_ex-w-5 yep_ex-h-5" />
        </button>
        {/* <button
          className="yep_ex-text-primary-700"
          onClick={() => setShowCustomVoiceModal(true)}
          // onClick={} // todo: Add clone voices feature
        >
          Create custom voice
        </button> */}
        <button
          className={`yep_ex-flex yep_ex-items-center yep_ex-justify-center yep_ex-border yep_ex-border-gray-300 yep_ex-rounded-lg yep_ex-py-2.5 yep_ex-px-4 yep_ex-text-sm`}
          onClick={() => setMobileLanguagesOpen(true)}
        >
          {filters.language.country ? (
            <img
              src={filters.language.country.image}
              alt={filters.language.name}
              className="yep_ex-w-5"
            />
          ) : (
            filters.language.name
          )}
        </button>
      </div>
      <Divider className="yep_ex-w-full md:yep_ex-hidden" />
      <Transition
        enter="yep_ex-transition yep_ex-duration-100 yep_ex-ease-out"
        enterFrom="yep_ex-transform yep_ex-scale-95 yep_ex-opacity-0"
        enterTo="yep_ex-transform yep_ex-scale-100 yep_ex-opacity-100"
        leave="yep_ex-transition yep_ex-duration-75 yep_ex-ease-out"
        leaveFrom="yep_ex-transform yep_ex-scale-100 yep_ex-opacity-100"
        leaveTo="yep_ex-transform yep_ex-scale-95 yep_ex-opacity-0"
        as={Fragment}
        show={mobileLanguagesOpen}
      >
        <div className="yep_ex-grid yep_ex-h-full yep_ex-grid-rows-[auto,1fr] yep_ex-overflow-y-auto yep_ex-z-50 yep_ex-bg-white max-md:yep_ex-top-0 max-md:yep_ex-left-0 yep_ex-absolute yep_ex-right-0 md:yep_ex-mt-0.5 yep_ex-w-full md:yep_ex-rounded-md md:yep_ex-shadow-lg md:yep_ex-border yep_ex-border-neutral-200">
          <div className="yep_ex-min-h-[3.5rem] yep_ex-border yep_ex-border-neutral-200 yep_ex-px-4 yep_ex-flex yep_ex-justify-between yep_ex-w-full yep_ex-items-center">
            <span className="yep_ex-text-sm yep_ex-font-inter yep_ex-font-semibold">
              <button
                onClick={() => setMobileLanguagesOpen(false)}
                className="yep_ex-stroke-neutral-600 yep_ex-text-neutral-600 yep_ex-flex yep_ex-items-center yep_ex-justify-center"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="yep_ex-mr-2"
                >
                  <path
                    d="M8.33333 15.8346L2.5 10.0013M2.5 10.0013L8.33333 4.16797M2.5 10.0013L17.5 10.0013"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Back
              </button>
            </span>
          </div>
          <div className="yep_ex-overflow-y-auto yep_ex-pb-0.5">
            <div className="yep_ex-px-4 yep_ex-pt-4 yep_ex-flex yep_ex-flex-col yep_ex-gap-y-2 yep_ex-basis-full yep_ex-pb-1">
              {languages.map((l) => (
                <div
                  className={clsx(
                    `yep_ex-flex yep_ex-items-center yep_ex-gap-x-2 yep_ex-px-1.5 yep_ex-py-3 yep_ex-w-full yep_ex-border yep_ex-text-neutral-600 
        yep_ex-text-sm disabled:yep_ex-opacity-50 yep_ex-rounded-lg hover:yep_ex-border-primary-600`,
                    filters.language.name === l.name
                      ? 'yep_ex-border-primary-600'
                      : 'yep_ex-border-neutral-200 yep_ex-cursor-pointer'
                  )}
                  onClick={() =>
                    setFilters({
                      ...filters,
                      language: l
                    })
                  }
                  key={l.name}
                >
                  {l.country && (
                    <img
                      src={l.country.image}
                      alt={l.name}
                      className="yep_ex-w-3.5"
                    />
                  )}
                  {l.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Transition>
      <Transition
        enter="yep_ex-transition yep_ex-duration-100 yep_ex-ease-out"
        enterFrom="yep_ex-transform yep_ex-scale-95 yep_ex-opacity-0"
        enterTo="yep_ex-transform yep_ex-scale-100 yep_ex-opacity-100"
        leave="yep_ex-transition yep_ex-duration-75 yep_ex-ease-out"
        leaveFrom="yep_ex-transform yep_ex-scale-100 yep_ex-opacity-100"
        leaveTo="yep_ex-transform yep_ex-scale-95 yep_ex-opacity-0"
        as={Fragment}
        show={mobileFiltersOpen}
      >
        <div className="yep_ex-grid yep_ex-h-full yep_ex-grid-rows-[auto,1fr] yep_ex-z-50 yep_ex-bg-white max-md:yep_ex-top-0 max-md:yep_ex-left-0 yep_ex-absolute yep_ex-right-0 md:yep_ex-mt-0.5 yep_ex-w-full md:yep_ex-rounded-md md:yep_ex-shadow-lg md:yep_ex-border yep_ex-border-neutral-200">
          <div className="yep_ex-min-h-[3.5rem] yep_ex-border yep_ex-border-neutral-200 yep_ex-px-4 yep_ex-flex yep_ex-justify-between yep_ex-w-full yep_ex-items-center">
            <span className="yep_ex-text-sm yep_ex-font-inter yep_ex-font-semibold">
              <button
                onClick={() => setMobileFiltersOpen(false)}
                className="yep_ex-stroke-neutral-600 yep_ex-text-neutral-600 yep_ex-flex yep_ex-items-center yep_ex-justify-center"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="yep_ex-mr-2"
                >
                  <path
                    d="M8.33333 15.8346L2.5 10.0013M2.5 10.0013L8.33333 4.16797M2.5 10.0013L17.5 10.0013"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Back
              </button>
            </span>
            <button
              className="yep_ex-text-sm yep_ex-font-inter yep_ex-font-semibold yep_ex-text-primary-700 disabled:yep_ex-text-gray-300 disabled:yep_ex-pointer-events-none"
              onClick={() => {
                resetGenders();
                setFilters({
                  ...filters,
                  provider: ['All providers']
                });
              }}
              disabled={
                genders.length === 0 && filters.provider[0] === 'All providers'
              }
            >
              Reset
            </button>
          </div>
          {/* // TODO: Return filters back when we have uploading flow
            <Checkbox
              label="Talking Photo"
              onClick={(value) => setTypeFilters(value as AvatarOptions)}
              choosenValues={typeFilters}
              value="talkingPhoto"
            /> */}
          {/* <Checkbox
              label="Avatar"
              onClick={(value) => setTypeFilters(value as AvatarOptions)}
              choosenValues={typeFilters}
              value="avatar"
            /> */}
          {/* <Checkbox
              label="Custom"
              onClick={(value) => setTypeFilters(value as AvatarOptions)}
              choosenValues={typeFilters}
              value="custom"
            />  */}
          {/* <Divider className="w-full my-2" /> */}
          <div className="yep_ex-overflow-y-auto yep_ex-pb-1">
            <div className="yep_ex-flex yep_ex-flex-col yep_ex-items-start yep_ex-gap-4 yep_ex-justify-evenly yep_ex-pl-4 yep_ex-pr-4 yep_ex-mt-4">
              <div className="yep_ex-pt-2 yep_ex-text-neutral-900 yep_ex-text-sm yep_ex-font-semibold">
                Gender
              </div>
              <Checkbox
                key="filter-male"
                label="Male"
                onClick={(value) => changeGender(value as GenderOptions)}
                choosenValues={genders}
                value="male"
              />
              <Checkbox
                key="filter-female"
                label="Female"
                onClick={(value) => changeGender(value as GenderOptions)}
                choosenValues={genders}
                value="female"
              />
            </div>
            <div className="yep_ex-flex yep_ex-flex-col yep_ex-items-start yep_ex-gap-4 yep_ex-justify-evenly yep_ex-pl-4 yep_ex-pr-4 yep_ex-mt-4">
              <div className="yep_ex-pt-2 yep_ex-text-neutral-900 yep_ex-text-sm yep_ex-font-semibold">
                Provider
              </div>
              {providers
                .filter((p) => p !== 'All providers')
                .map((p) => (
                  <Checkbox
                    key={`filter-${p}`}
                    label={p}
                    onClick={(value) => {
                      let newProviders: string[];
                      if (filters.provider.includes(value)) {
                        newProviders = filters.provider.filter(
                          (g) => g !== value
                        );

                        if (newProviders.length === 0)
                          newProviders.push('All providers');
                      } else {
                        newProviders = [...filters.provider, value];

                        const allProvidersInx =
                          newProviders.indexOf('All providers');
                        if (allProvidersInx !== -1)
                          newProviders.splice(allProvidersInx, 1);
                      }
                      setFilters({
                        ...filters,
                        provider: newProviders
                      });
                    }}
                    choosenValues={filters.provider}
                    value={p}
                  />
                ))}
            </div>
          </div>
        </div>
      </Transition>
      {showCustomVoiceModal && (
        <WaitlistForm
          onClose={() => setShowCustomVoiceModal(false)}
          featureName="custom_voice"
          subtitle="Fill the form below to get access to custom voices. Our team will contact you shortly."
          label="How would you use custom voices?"
          placeholder="I want to use custom voices for..."
        />
      )}
    </>
  );
};

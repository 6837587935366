import React, { Dispatch, SetStateAction } from 'react';
import { PrimaryButton } from 'shared';

type Step0Props = {
  onSetStep: Dispatch<SetStateAction<number>>;
};

const Step0: React.FC<Step0Props> = ({ onSetStep }) => {
  return (
    <>
      <h1 className="yep_ex-text-lg yep_ex-font-semibold yep_ex-mb-2">
        Create your Talking Photo
      </h1>
      <p className="yep_ex-text-neutral-400 yep_ex-mb-2">
        Create your own talking photo in less than 3 simple steps.{' '}
      </p>
      <div className="yep_ex-my-6">
        <iframe
          className="yep_ex-w-full yep_ex-aspect-video"
          src="https://www.youtube.com/embed/ozpLvZEdVsA"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        ></iframe>
      </div>

      <div className="sm:yep_ex-absolute yep_ex-right-4 yep_ex-bottom-4 yep_ex-flex yep_ex-justify-center sm:yep_ex-block mt-4">
        <PrimaryButton
          onClick={() => onSetStep((prevState) => prevState + 1)}
          className="yep_ex-py-2.5 yep_ex-px-16 yep_ex-min-w-[180px]"
        >
          Next
        </PrimaryButton>
      </div>
    </>
  );
};

export default Step0;

import { avatarStore } from 'entities/avatar';
import React from 'react';

export const CanvasCTA = () => {
  const setShowCustomPhotoModal = avatarStore(
    (state) => state.setShowCustomPhotoModal
  );
  return (
    <div className="yep_ex-justify-center yep_ex-h-full yep_ex-items-center yep_ex-flex yep_ex-flex-wrap yep_ex-flex-col yep_ex-gap-2 yep_ex-px-6">
      <p>Choose a Talking Photo</p>
      <p>or</p>
      <button
        className=" yep_ex-text-primary-600 hover:yep_ex-underline"
        type="button"
        onClick={() => setShowCustomPhotoModal(true)}
      >
        Upload your own
      </button>
    </div>
  );
};

import {
  createElement,
  ReactHTML,
  useEffect,
  useRef,
  ReactNode,
  FC
} from 'react';

interface DropZoneProps<T extends keyof ReactHTML = keyof ReactHTML> {
  children: ReactNode;
  onDrop: (e: Event) => void;
  onDrag: (e: Event) => void;
  as: keyof ReactHTML;
  props: JSX.IntrinsicElements[T];
}

const DropZone: FC<DropZoneProps> = ({
  children,
  onDrop,
  onDrag,
  as = 'div',
  props
}) => {
  const drop = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const { current } = drop;

    if (!current) return;

    current.addEventListener('dragover', onDrag);
    current.addEventListener('drop', onDrop);

    return () => {
      current.removeEventListener('dragover', onDrag);
      current.removeEventListener('drop', onDrop);
    };
  }, [onDrag, onDrop]);

  return createElement(
    as,
    {
      ref: drop,
      ...props
    },
    children
  );
};

export default DropZone;

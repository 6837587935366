import * as Yup from 'yup';
import { useFormik } from 'formik';
import clsx from 'clsx';

import {
  JoinWaitlistRequest,
  Loader,
  Modal,
  OutlineButton,
  PrimaryButton,
  showError,
  showSuccessMessage
} from 'shared';
import { joinWaitlist } from '../model';

type WaitlistFormProps = {
  onClose: () => void;
  featureName?: string;
  subtitle?: string;
  label?: string;
  placeholder?: string;
};

export const WaitlistForm = ({
  onClose,
  featureName,
  subtitle,
  label,
  placeholder
}: WaitlistFormProps) => {
  const formik = useFormik<Omit<JoinWaitlistRequest, 'email'>>({
    initialValues: {
      feature_request: featureName || '',
      feature_request_details: ''
    },
    validationSchema: Yup.object({
      feature_request: Yup.string(),
      feature_request_details: Yup.string()
    }),
    onSubmit: async (values) => await handleSubmit(values)
  });

  const handleSubmit = async (values: Omit<JoinWaitlistRequest, 'email'>) => {
    const onError = (errorMessage: string) => {
      showError(errorMessage);
      onClose();
    };
    const data = await joinWaitlist(values, onError);
    if (data) {
      showSuccessMessage('Thank you for joining the waitlist!');
      onClose();
    }
  };

  return (
    <Modal
      open
      onClose={onClose}
      widthClasses="yep_ex-rounded-lg max-w-md sm:yep_ex-my-8"
      noPadding
    >
      <form
        className="yep_ex-bg-white md:yep_ex-rounded-xl yep_ex-w-full yep_ex-flex max-md:yep_ex-min-h-screen yep_ex-flex-col md:yep_ex-gap-5 yep_ex-z-20 yep_ex-h-full 
                      yep_ex-px-6 yep_ex-pb-4 yep_ex-pt-6 md:yep_ex-pt-5 md:yep_ex-mt-4 md:yep_ex-p-6"
        onSubmit={formik.handleSubmit}
      >
        <div className="max-md:yep_ex-mb-12 yep_ex-mb-8">
          <h2 className="yep_ex-text-lg yep_ex-font-medium yep_ex-text-neutral-900 yep_ex-leading-7">
            Request a Feature
          </h2>
          <p className="yep_ex-text-neutral-700 yep_ex-text-sm yep_ex-font-light">
            {subtitle}
          </p>
        </div>
        <div className="max-md:yep_ex-my-10 yep_ex-flex yep_ex-flex-col yep_ex-gap-4 md:yep_ex-gap-3 yep_ex-text-sm">
          <div className="yep_ex-flex yep_ex-flex-col yep_ex-text-sm">
            <label
              className="yep_ex-mb-1.5 yep_ex-text-neutral-700"
              htmlFor="usage"
            >
              {label}
            </label>
            <textarea
              className={clsx(
                'yep_ex-border yep_ex-border-neutral-300 yep_ex-py-1.5 yep_ex-px-3 yep_ex-h-32 yep_ex-rounded-md yep_ex-text-sm',
                formik.errors.feature_request_details &&
                  formik.touched.feature_request_details
                  ? 'yep_ex-ring-red-300 placeholder:yep_ex-text-red-300 yep_ex-text-red-900 focus:yep_ex-ring-red-500 focus:yep_ex-border-red-500'
                  : 'yep_ex-text-gray-900 placeholder:yep_ex-text-gray-400 focus:yep_ex-ring-primary-600 focus:yep_ex-border-primary-600 '
              )}
              placeholder={placeholder}
              name="feature_request_details"
              id="feature_request_details"
              rows={5}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.feature_request_details}
            />
            {formik.errors.feature_request_details &&
              formik.touched.feature_request_details && (
                <p className="yep_ex-mt-2 yep_ex-text-sm yep_ex-text-red-600 yep_ex-text-left yep_ex-block">
                  {formik.errors.feature_request_details}
                </p>
              )}
          </div>
        </div>
        <div className="yep_ex-grid yep_ex-grid-cols-2 yep_ex-gap-4 yep_ex-mt-8">
          <OutlineButton
            className="yep_ex-py-2 yep_ex-text-base yep_ex-h-11 yep_ex-grow"
            onClick={onClose}
          >
            Cancel
          </OutlineButton>
          <PrimaryButton
            className="yep_ex-py-2 yep_ex-text-base yep_ex-h-11 yep_ex-grow"
            type="submit"
          >
            {formik.isSubmitting ? (
              <div className=" yep_ex-flex yep_ex-justify-center">
                <Loader color="#fff" />
              </div>
            ) : (
              'Request'
            )}
          </PrimaryButton>
        </div>
      </form>
    </Modal>
  );
};

export const BackButton = () => (
  <button onClick={() => (window.location.href = './')}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33333 15.8346L2.5 10.0013M2.5 10.0013L8.33333 4.16797M2.5 10.0013L17.5 10.0013"
        stroke="#B015FF"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </button>
);

const getEnvVar = (key: string) => {
  if (process.env[key] === undefined) {
    throw new Error(`Env variable ${key} is required`);
  }
  return process.env[key] || '';
};

export const AUTH_ROUTE = '/login';
export const SIGNUP_ROUTE = '/register';

export const DEFAULT_VOICE = 'az-en-US-AvaMultilingual';
export const DEFAULT_MALE_VOICE = 'po-en-US-matthew';
export const DEFAULT_FEMALE_VOICE = 'az-en-US-JennyMultilingual';

export const SENTRY_AUTH_TOKEN = getEnvVar('REACT_APP_SENTRY_AUTH_TOKEN');
export const ENVIRONMENT = getEnvVar('REACT_APP_ENVIRONMENT');
export const BASEURL_DOTNET = getEnvVar('REACT_APP_BASEURL_DOTNET');
export const EMAIL_VALIDATION_URL = getEnvVar('REACT_APP_EMAIL_VALIDATION_URL');
export const CHAT_BACKEND_URL = getEnvVar('REACT_APP_CHAT_BACKEND_URL');
export const CHAT_BACKEND_URL_WS = getEnvVar('REACT_APP_CHAT_BACKEND_URL_WS');
export const AMPLITUDE_API_KEY = getEnvVar('REACT_APP_AMPLITUDE_API_KEY');

// * Feature flags
export const FEATURE_FLAG_CANVAS = getEnvVar('REACT_APP_FEATURE_FLAG_CANVAS');
export const FEATURE_FLAG_FORMAT = getEnvVar('REACT_APP_FEATURE_FLAG_FORMAT');
export const FEATURE_FLAG_STYLE = getEnvVar('REACT_APP_FEATURE_FLAG_STYLE');
export const FEATURE_FLAG_CHATGPT = getEnvVar('REACT_APP_FEATURE_FLAG_CHATGPT');
export const FEATURE_FLAG_BACKGROUND_COLOR = getEnvVar(
  'REACT_APP_FEATURE_FLAG_BACKGROUND_COLOR'
);
export const FEATURE_FLAG_CROP_ACTOR = getEnvVar(
  'REACT_APP_FEATURE_FLAG_CROP_ACTOR'
);
export const FEATURE_FLAG_DYNAMIC_ACTOR = getEnvVar(
  'REACT_APP_FEATURE_FLAG_DYNAMIC_ACTOR'
);
export const FEATURE_FLAG_STARTERS_USE_PREMIUM_VOICES = getEnvVar(
  'REACT_APP_FEATURE_FLAG_STARTERS_USE_PREMIUM_VOICES'
);

import { uploadPhotoApi } from '../api';

export const detectFace = async (file: File): Promise<any> => {
  const formData = new FormData();
  formData.append('Photo', file);
  try {
    const data = await uploadPhotoApi.detectFace(formData);
    if (
      Object.prototype.hasOwnProperty.call(data, 'errorCode') &&
      data.errorCode !== null &&
      data.errorCode !== '' &&
      typeof data.errorCode !== 'undefined'
    ) {
      if (data?.status?.http_code === 400) {
        throw new Error(
          'An error occurred while detecting the face on your photo, check if the photo is correct.'
        );
      } else if (data?.status?.http_code === 500) {
        throw new Error(
          'Sorry, there was an error detecting the face due to a server issue. Please try again later.'
        );
      } else {
        throw new Error(
          'An unexpected error occurred while detecting the face on your photo. Please try again later.'
        );
      }
    } else {
      return data;
    }
  } catch (error) {
    throw new Error(
      `There was a problem when we tried to detect the face on your photo. Error: "${
        (error as Error).message
      }"`
    );
  }
};

import { create } from 'zustand';
import { SavedVideoProject, StyleOptions, Voiceover } from 'shared';
import { persist, createJSONStorage } from 'zustand/middleware';

type VideoState = {
  videoTitle: string;
  currentStyle: StyleOptions;
  scriptText: string;
  voiceover: Voiceover | null;
  backgroundColor: string;
};

type VideoActions = {
  setVideoTitle: (newTitle: string) => void;
  setStyle: (newStyle: StyleOptions) => void;
  setScriptText: (newScriptText: string) => void;
  setVoiceover: (newVoiceover: Voiceover | null) => void;
  setBackgroundColor: (newColor: string) => void;
};

export type VideoStore = VideoState & VideoActions;

export const videoStore = create(
  persist<VideoStore>(
    (set) => ({
      videoTitle: '',
      setVideoTitle: (newTitle) => set({ videoTitle: newTitle }),
      currentStyle: 'friendly',
      setStyle: (newStyle) => set({ currentStyle: newStyle }),
      scriptText: '',
      setScriptText: (newScriptText) =>
        set({ scriptText: newScriptText, voiceover: null }),
      voiceover: null,
      setVoiceover: (newVoiceover) => {
        set({ voiceover: newVoiceover, scriptText: '' });
      },
      backgroundColor: '#ffffff',
      setBackgroundColor: (newColor) => set({ backgroundColor: newColor })
    }),
    {
      name: 'video-store',
      storage: createJSONStorage(() => localStorage),
      merge: (persistedState, currentState) => {
        const processedPersistedState = persistedState as VideoStore;
        if (processedPersistedState.voiceover) {
          const audio = new Audio(processedPersistedState.voiceover.link);
          audio.onloadedmetadata = () => {
            currentState.setVoiceover({
              name: processedPersistedState.voiceover?.name ?? '',
              link: processedPersistedState.voiceover?.link ?? '',
              audio
            });
          };
        }
        return {
          ...currentState,
          ...processedPersistedState
        };
      }
    }
  )
);
